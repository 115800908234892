import { ButtonRole, ButtonTargetKind } from "@components/Button";
import {
  StyledButton,
  StyledIcon,
  ButtonContent,
} from "@components/Home/LandingPage/LandingTab/styles";
import { LandingUserTypeTab } from "@components/Home/LandingPage/types";
import { IconDisplay, IconSize } from "@components/Icon";

const IconForLandingTab = {
  [LandingUserTypeTab.DONORS]: () => import("@components/Icon/icons/User"),
  [LandingUserTypeTab.NONPROFITS]: () =>
    import("@components/Icon/icons/OrganizationIcon"),
};

interface LandingTabsProps {
  selectedTab: LandingUserTypeTab;
  currentTab: LandingUserTypeTab;
  onChange: (selectedTab: LandingUserTypeTab) => void;
}

export function LandingTab({
  selectedTab,
  currentTab,
  onChange,
}: LandingTabsProps) {
  return (
    <StyledButton
      selected={currentTab === selectedTab}
      key={currentTab}
      data-tname={currentTab}
      role={ButtonRole.UNSTYLED}
      onClick={{
        kind: ButtonTargetKind.FUNCTION,
        action: () => {
          onChange(currentTab);
        },
      }}
    >
      <ButtonContent>
        <StyledIcon
          iconImport={IconForLandingTab[currentTab]}
          display={IconDisplay.CURRENT_COLOR}
          size={IconSize.MEDIUM}
        />
        <span>{currentTab}</span>
      </ButtonContent>
    </StyledButton>
  );
}

import { Button } from "@components/Button";
import { Icon } from "@components/Icon";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { spacing } from "@every.org/common/src/display/spacing";

import {
  lightBgThemeNoBgCss,
  colorCssVars,
  lightBgThemeCss,
} from "src/theme/color";
import { verticalStackCss } from "src/theme/spacing";
import { FontWeight } from "src/theme/text";

export const StyledIcon = styled(Icon)`
  color: var(${colorCssVars.accent.large});
`;

export const ButtonContent = styled.div`
  ${verticalStackCss.xs};
  align-items: center;
  font-weight: ${FontWeight.MEDIUM};
  padding: ${spacing.m} 0;
`;

const unselectedTabCss = css`
  ${lightBgThemeNoBgCss};

  background-color: #f3f6f6;
  border-bottom: 2px solid #eaeded;

  ${ButtonContent} {
    color: var(${colorCssVars.accent.large});
  }

  &:hover {
    ${ButtonContent}, ${StyledIcon} {
      color: var(${colorCssVars.accent.small});
    }
  }
`;

const selectedTabCss = css`
  ${lightBgThemeCss};
  ${ButtonContent} {
    border-bottom: 2px solid var(${colorCssVars.accent.large});
    color: var(${colorCssVars.text.body});
  }
`;

export const StyledButton = styled(Button)<{ selected: boolean }>`
  justify-content: center;
  display: flex;
  border-radius: 8px 8px 0 0;
  color: var(${colorCssVars.accent.large});

  padding: 0 ${spacing.m};
  span {
    width: unset;
  }

  ${({ selected }) => (selected ? selectedTabCss : unselectedTabCss)};
`;

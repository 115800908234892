import { Button } from "@components/Button";
import { Card } from "@components/Card";
import {
  MAX_LANDING_PAGE_SECTION_WIDTH,
  SECTION_WIDTH_VAR,
} from "@components/layout/PageSection";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { spacing } from "@every.org/common/src/display/spacing";

import { darkBgThemeCss, lightBgThemeNoBgCss } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { verticalStackCss } from "src/theme/spacing";

const BORDER_RADIUS = "8px";
const SEE_RECENT_DONATIONS_BUTTON_HEIGHT = "56px";

export const defaultPageLayoutCss = [
  darkBgThemeCss,
  css`
    ${SECTION_WIDTH_VAR}: ${MAX_LANDING_PAGE_SECTION_WIDTH};
  `,
];

export const defaultPageLayoutFooterCss = css`
  margin-top: 0;
`;

export const pageSectionCss = css`
  height: calc(100% - ${SEE_RECENT_DONATIONS_BUTTON_HEIGHT});
  padding-top: ${spacing.m};
  padding-bottom: ${spacing.m};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCard = styled(Card)`
  border-radius: 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS};
  padding: ${spacing.xl} ${spacing.l} ${spacing.l};

  ${cssForMediaSize({
    min: MediaSize.MEDIUM_LARGE,
    css: css`
      padding: calc(2 * ${spacing.l}) ${spacing.xxl};
    `,
  })};
`;

export const LandingContentWrapper = styled.div`
  ${verticalStackCss.none}
  max-width: 600px;
  width: 100%;
`;

export const LandingContentTabsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${spacing.xs};
`;

export const LandingSeeRecentDonationsButtonsSection = styled.section`
  ${lightBgThemeNoBgCss};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  display: flex;
  gap: ${spacing.m};
  flex-direction: column;
  align-items: center;
`;

export const LandingSeeRecentDonationsButton = styled(Button)`
  height: ${SEE_RECENT_DONATIONS_BUTTON_HEIGHT};
  width: 100%;
  border-radius: 0;
  padding: ${spacing.xs} 0;
`;

export const landingFeedPageSectionCss = css`
  padding-top: ${spacing.xxl};
`;

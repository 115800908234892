import { ABTest } from "./types";

export enum LoggedOutAATestVariant {
  CONTROL = "CONTROL",
  CONTROL_PRIME = "CONTROL_PRIME",
}

export const LoggedOutAATest: ABTest<LoggedOutAATestVariant> = {
  name: "2021-02-01-LoggedOutAATest",
  variants: {
    [LoggedOutAATestVariant.CONTROL]: 1,
    [LoggedOutAATestVariant.CONTROL_PRIME]: 1,
  },
};

import { Feed, FeedProps } from "@components/feed";
import { css } from "@emotion/react";
import { useMemo } from "react";

import { FeedItemResponse } from "@every.org/common/src/codecs/entities";
import { spacing } from "@every.org/common/src/display/spacing";
import { FeedPage } from "@every.org/common/src/entity/types";

import { fetchLandingData } from "src/context/DonationsContext/actions";
import { minWidthForMediaSize, MediaSize } from "src/theme/mediaQueries";

export function LandingFeed({
  initialItems,
}: {
  initialItems?: FeedItemResponse[];
}) {
  const getLandingFeed = useMemo<FeedProps["loadMoreItems"]>(() => {
    return () => fetchLandingData();
  }, []);

  return (
    <Feed
      page={FeedPage.LANDING}
      take={undefined}
      data-tname="landingFeed"
      initialItems={initialItems}
      loadMoreItems={getLandingFeed}
      showSkeleton
      numColumns={{
        [minWidthForMediaSize[MediaSize.LARGE]]: 1,
        [minWidthForMediaSize[MediaSize.X_LARGE]]: 2,
        [minWidthForMediaSize[MediaSize.XX_LARGE]]: 3,
        default: 4,
      }}
      css={css`
        .everydotorg--MasonryGridColumn:nth-child(2n) {
          padding-top: ${spacing.xxl};
        }
      `}
    />
  );
}

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import {
  verticalStackCss,
  horizontalStackCss,
  spacing,
} from "src/theme/spacing";

export const SocialLoginButtonsContainer = styled.div`
  button:not(.signupEmail) {
    height: 56px;
  }
  .signupEmail {
    padding: ${spacing.xs};
  }
  ${cssForMediaSize({
    max: MediaSize.MEDIUM,
    css: verticalStackCss.xs,
  })}
  ${cssForMediaSize({
    min: MediaSize.MEDIUM_LARGE,
    css: [
      horizontalStackCss.xs,
      css`
        align-items: center;
      `,
    ],
  })}
`;

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { spacing } from "@every.org/common/src/display/spacing";

import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { TextCssVar, textSizeCss, FontWeight } from "src/theme/text";

export const LandingTabContainer = styled.div`
  h1 {
    ${TextCssVar.SIZE}: 28px;
    ${TextCssVar.LINE_HEIGHT}: 32px;
    margin-bottom: ${spacing.xs};
  }

  h2 {
    ${TextCssVar.SIZE}: 20px;
    ${TextCssVar.LINE_HEIGHT}: 28px;
    margin-bottom: ${spacing.xl};
    color: #ac6b28;
    letter-spacing: -0.01em;
  }

  h5 {
    ${textSizeCss.s};
    font-weight: ${FontWeight.REGULAR};
    color: var(${colorCssVars.text.secondary});
  }

  p {
    ${textSizeCss.s};
    color: var(${colorCssVars.text.secondary});
    margin-bottom: ${spacing.l};
  }

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      h1 {
        ${TextCssVar.SIZE}: 40px;
        ${TextCssVar.LINE_HEIGHT}: 48px;
      }

      h2 {
        ${TextCssVar.SIZE}: 24px;
        ${TextCssVar.LINE_HEIGHT}: 32px;
        margin-bottom: ${spacing.l};
      }

      p {
        ${TextCssVar.SIZE}: 20px;
        ${TextCssVar.LINE_HEIGHT}: 28px;
        color: var(${colorCssVars.text.secondary});
      }
    `,
  })}
`;

export const LandingNonprofitsTabContainer = styled(LandingTabContainer)`
  h2 {
    margin-bottom: ${spacing.m};
  }
  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      h1 {
        margin-bottom: ${spacing.s};
      }
      h2 {
        ${TextCssVar.SIZE}: 20px;
        ${TextCssVar.LINE_HEIGHT}: 28px;
        margin-bottom: ${spacing.m};
      }
    `,
  })}
`;

import { Button, ButtonTargetKind, ButtonRole } from "@components/Button";
import { SocialLoginButtonsContainer } from "@components/Home/LandingPage/LandingSignup/styles";
import { Modal } from "@components/Modal";
import { EmailSignupForm } from "@components/Signup/EmailSignupForm";
import { SocialLoginButton } from "@components/SocialLoginButton";
import { useState } from "react";

import { IdentityProvider } from "@every.org/common/src/codecs/auth";

import { verticalStackCss } from "src/theme/spacing";

export const LandingSignup = ({ redirectUrl }: { redirectUrl?: string }) => {
  const [signupOpen, setSignupOpen] = useState(false);

  return (
    <div css={verticalStackCss.s}>
      <Modal
        isOpen={signupOpen}
        onRequestClose={() => setSignupOpen(false)}
        headerText="Sign up"
        showHeader
      >
        <EmailSignupForm isModal redirectUrl={redirectUrl} />
      </Modal>
      <SocialLoginButtonsContainer>
        <SocialLoginButton
          idp={IdentityProvider.GOOGLE}
          data-tname="signupGoogle"
          redirectUrl={redirectUrl}
        >
          Join with Google
        </SocialLoginButton>
        <Button
          data-tname="signupEmail"
          className="signupEmail"
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: () => setSignupOpen(true),
          }}
          role={ButtonRole.TEXT_ONLY}
        >
          Sign up with email
        </Button>
      </SocialLoginButtonsContainer>
    </div>
  );
};
